import './modules/body-width-fix';
import './modules/modals';
import './modules/forms';
import './modules/masks';
import './modules/spinner';
import './modules/scroll';
import './modules/menus';
import './modules/tabs';
import './modules/galleries';
import './modules/rotators';
import './modules/spoilers';
import './modules/catalogue';
import './modules/card';
import './modules/order';
// import ClosableTeaser from './modules/widgets/closable-teaser';

// Init closable teaser
// --------------------

// const teaser = new ClosableTeaser({
//   selector: '.js-floating-teaser',
//   closeSelector: '.js-close-floating-teaser',
//   storageKey: 'TEASER_HIDE_DATE',
//   daysToHide: 7,
// });
